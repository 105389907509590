import type { ComponentStyleConfig } from "@chakra-ui/theme";

const heading: ComponentStyleConfig = {
  baseStyle: {
    color: "chakra-body-text-darkest",
    fontWeight: "bold",
    letterSpacing: "ls.100",
    lineHeight: "taller",
  },
  sizes: {
    lg: {
      lineHeight: "taller",
      fontWeight: "bold",
      fontSize: "fs.600",
    },
    md: {
      fontSize: "fs.500",
    },
  },
};

export default heading;
``;
