import { ServerError } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import standaloneToast from "@/utils/standaloneToast";

const { toast } = standaloneToast;

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, response, forward, operation }) => {
  // Redirect to login flow if user is not authenticated
  if (networkError) {
    if ((networkError as ServerError).statusCode === 401) {
      if (typeof window !== "undefined") {
        window.location.replace("/login");
        return;
      }
    }
  }

  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (!toast.isActive(message)) {
        toast({
          id: message,
          title: "An error occurred.",
          description: message,
          status: "error",
          duration: null,
          isClosable: true,
        });
      }
    });

  forward(operation);
});

export default errorLink;
