const textStyles = {
  overlineText: {
    fontWeight: "normal",
    fontSize: "fs.25",
    letterSpacing: "ls.250",
    textTransform: "uppercase",
  },
  release: {
    color: "chakra-trinary",
    display: "inline",
    fontWeight: "700",
  },
};

export default textStyles;
