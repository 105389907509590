import type { ComponentStyleConfig } from "@chakra-ui/theme";
import { mode } from "@chakra-ui/theme-tools";

const link: ComponentStyleConfig = {
  baseStyle: props => {
    const { colorScheme: c } = props;

    return {
      fontWeight: "medium",
      color: mode(`${c}.500`, `${c}.400`)(props),
      letterSpacing: "ls.50",
    };
  },
  defaultProps: {
    colorScheme: "niagara",
  },
  variants: {
    buttonWrapper: {
      textDecoration: "none",
      ":hover": {
        textDecoration: "none",
      },
    },
  },
};

export default link;
