import type { ComponentStyleConfig } from "@chakra-ui/theme";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";

const list: ComponentStyleConfig = {
  variants: {
    navMenu: (props: StyleFunctionProps) => ({
      item: {
        "&[href]": {
          _hover: {
            bg: "blackAlpha.200",
            '[data-active="true"]': {
              bg: "white",
            },
          },
        },
        transitionProperty: "common",
        transitionDuration: "normal",
        letterSpacing: "ls.200",
        color: "chakra-body-text-darker",
        borderRadius: "md",
        p: 4,
        w: "full",
        display: "flex",
        fontWeight: "medium",
        '&[aria-current="step"], &[aria-current="page"]': {
          bg: "chakra-body-bg",
          color: "chakra-primary",
        },
        '&[data-checked="true"] > svg': {
          color: "chakra-secondary",
        },

        _active: {
          _hover: {
            transform: "scale(.98)",
            bg: "blackAlpha.300",
          },
        },
      },
      icon: {
        w: "1.25rem",
        h: "1.25rem",
      },
    }),
  },
};

export default list;
