/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";

import { ApolloClient, from, InMemoryCache, NormalizedCacheObject, split } from "@apollo/client";
import { getMainDefinition, relayStylePagination } from "@apollo/client/utilities";
import merge from "deepmerge";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";
import { isEqual } from "lodash";

import possibleTypesData from "@/generated/possibleTypes";

import apolloLink from "./apolloLink";
import errorLink from "./errorLink";
import networkErrorResolvedLink from "./networkErrorResolvedLink";
import retryLink from "./retryLink";
import userErrorLink from "./userErrorLink";
import { wsLink } from "./wsLink";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

function createApolloClient() {
  const ssrMode = typeof window === "undefined";
  const splitLink = ssrMode
    ? apolloLink(undefined, ssrMode)
    : split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === "OperationDefinition" && definition.operation === "subscription";
        },
        new ActionCableLink({ cable: wsLink(ssrMode) }),
        apolloLink(undefined, ssrMode)
      );
  return new ApolloClient({
    ssrMode,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
    link: from([retryLink, errorLink, userErrorLink, networkErrorResolvedLink, splitLink]),
    cache: new InMemoryCache({
      possibleTypes: possibleTypesData.possibleTypes,
      typePolicies: {
        Workspace: {
          fields: {
            cloudIntegrations: relayStylePagination(),
            databasePools: relayStylePagination(),
            clusters: relayStylePagination(),
          },
        },
        CloudIntegration: {
          fields: {
            databasePools: relayStylePagination(),
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState: any = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}
export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any) {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo(state), [state]);
}
