import type { ComponentStyleConfig } from "@chakra-ui/theme";

const text: ComponentStyleConfig = {
  variants: {
    code: {
      color: "#9A2F8F",
      backgroundColor: "#F6F6F8",
      borderRadius: "4px",
      padding: "4px 12px",
    },
    verticalLabel: {
      writingMode: "vertical-lr",
      fontWeight: "medium",
      letterSpacing: "ls.200",
    },
  },
};

export default text;
