import { ApolloLink } from "@apollo/client";

import standaloneToast from "@/utils/standaloneToast";

const { toast } = standaloneToast;

const networkErrorResolvedLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    // Close retryToasts after successful response
    toast.close(operation.getContext().retryToast);
    return response;
  });
});
export default networkErrorResolvedLink;
