/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, ReactNode } from "react";

import { ApolloClient, ApolloProvider } from "@apollo/client";
import { NextPage } from "next/types";
import { Helmet } from "react-helmet";

import "@/styles/fonts/satoshi.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto-mono/400.css";
import "overlayscrollbars/css/OverlayScrollbars.css";
import "@/styles/common.css";
import "@fontsource/space-mono";

import { useApollo } from "@/apollo/apolloClient";
import { Chakra } from "@/Chakra";
import BaseLayout from "@/layouts/BaseLayout";

import type { AppProps } from "next/app";

if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  require("@/msw");
}

type PageProps = { cookies: { [key: string]: string } };

interface Props {
  Component: React.FC<PageProps>;
  apollo: ApolloClient<unknown>;
}

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<PageProps> & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: Props & AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? (page => page);
  const apolloClient = useApollo(pageProps);

  return (
    <>
      <Helmet>
        <title>Release</title>
      </Helmet>
      <ApolloProvider client={apolloClient}>
        <Chakra cookies={pageProps.cookies}>
          <BaseLayout>{getLayout(<Component {...pageProps} />)}</BaseLayout>
        </Chakra>
      </ApolloProvider>
    </>
  );
}

export default App;
