/* eslint-disable react/jsx-filename-extension */

import { useState, useEffect } from "react";

import { Cable } from "actioncable";

import createProvider from "@/providerFactories/createProvider";

type CreateAppProviderType = Cable | undefined;

type InitialValue = {
  url?: string;
  ready?: boolean;
};

const useActionCableConnectionHook = ({ url, ready = true }: InitialValue) => {
  const [connection, setConnection] = useState<Cable>();

  useEffect(() => {
    async function createConnection() {
      if (ready) {
        if (url) {
          const actioncable = await import("actioncable");
          const connection = actioncable.createConsumer(url);
          setConnection(connection);
        } else {
          console.error(`URL is undefined`);
        }
      }
    }
    createConnection();
    return () => connection?.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, ready]);

  return connection;
};

export const [useActionCableConnection, ActionCableConnectionProvider, ActionCableConnectionConsumer] =
  createProvider<CreateAppProviderType, InitialValue>(undefined, useActionCableConnectionHook);
