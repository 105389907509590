import { darken } from "@chakra-ui/theme-tools";

const colors = {
  brand: {
    // 200: "#B794F4",
    "500": "#9A2F8F", // Plum
    "50": "#F9EBF8",
    "100": "#EEC8EA",
    "200": "#E4A5DD",
    "300": "#D982D0",
    "400": "#CE5FC3",
    // "500": "#C33CB5",
    "600": "#9C3091",
    700: "#29063E",
    // "700": "#75246D",
    "800": "#4E1849",
    "900": "#270C24",
  },
  violet: {
    500: "#29063E",
    "50": "#F5E8FD",
    "100": "#E3BEF9",
    "200": "#D094F5",
    "300": "#BE6AF1",
    "400": "#AC40ED",
    // "500": "#9A17E9",
    "600": "#7B12BA",
    "700": "#5C0E8B",
    "800": "#3E095D",
    "900": "#1F042F",
  },
  niagara: {
    500: "#00BB93", // Mint green
    "50": "#E5FFFA",
    "100": "#B8FFF0",
    "200": "#8AFFE6",
    "300": "#5CFFDC",
    "400": "#2EFFD2",
    // "500": "#00FFC8",
    "600": "#00CCA0",
    "700": "#009978",
    "800": "#006650",
    "900": "#003328",
  },
  gray: {
    30: "#F6F6F8", // athens gray 50
    40: "#EFEDF2", // gray haze 100
    50: "#EAE8ED", // icicle 200
    100: "#E1DFE5", // iron 300
    200: "#D1CED7", // ghost 400
    300: "#C0BDC7", // ash / 500
    400: "#A09CA8", // mist 600
    500: "#7F7B87", // topaz 700
    600: "#595461", // gravel 800
    700: "#413C4A", // ship gray 900
    800: darken("#413C4A", 5)({}),
    900: darken("#413C4A", 8)({}),
  },
  water: {
    100: "#F2F2FB",
    300: "#a9a7ff",
    500: "#5E5BCD",
    800: "#3c3c73",
  },
  brick: {
    "50": "#F9EDEC",
    "100": "#EDCDCA",
    "200": "#E1ADA7",
    "300": "#D68D85",
    "400": "#CA6D63",
    "500": "#AB453A",
    "600": "#983D34",
    "700": "#722E27",
    "800": "#4C1F1A",
    "900": "#260F0D",
  },
};

export default colors;
