import { extendTheme, withDefaultColorScheme, type ThemeConfig } from "@chakra-ui/react";
import { SystemStyleObject } from "@chakra-ui/system";
import { GlobalStyleProps } from "@chakra-ui/theme-tools";

import codemirror from "./codemirror";
import Alert from "./components/alert";
import Avatar from "./components/avatar";
import Badge from "./components/badge";
import Button from "./components/button";
import Code from "./components/code";
import Divider from "./components/divider";
import Drawer from "./components/drawer";
import Form from "./components/form";
import FormLabel from "./components/formLabel";
import Heading from "./components/heading";
import Input from "./components/input";
import Link from "./components/link";
import List from "./components/list";
import Menu from "./components/menu";
import Modal from "./components/modal";
import NumberInput from "./components/numberInput";
import Text from "./components/text";
import Tooltip from "./components/tooltip";
import borderRadius from "./foundations/borderRadius";
import colors from "./foundations/colors";
import sizes from "./foundations/sizes";
import spacing from "./foundations/spacing";
import { fonts, fontSizes, fontWeights, lineHeights, letterSpacings } from "./foundations/typography";
import layerStyles from "./layerStyles";
import textStyles from "./textStyles";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme(
  {
    codemirror,
    config,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
    letterSpacings,
    spacing,
    borderRadius,
    sizes,
    shadows: {
      outline: "0 0 0 3px rgb(154 47 143 / 38%)",
    },
    colors,
    semanticTokens: {
      colors: {
        "chakra-body-text-lighter": { _light: "gray.300", _dark: "whiteAlpha.500" },
        "chakra-body-text-light": { _light: "gray.400", _dark: "whiteAlpha.600" },
        "chakra-body-text": { _light: "gray.500", _dark: "whiteAlpha.700" },
        "chakra-body-text-darker": { _light: "gray.600", _dark: "whiteAlpha.800" },
        "chakra-body-text-darkest": { _light: "gray.700", _dark: "whiteAlpha.900" },
        "chakra-body-bg": { _light: "white", _dark: "gray.800" },
        "chakra-body-bg-darker": { _light: "gray.30", _dark: "gray.900" },
        "chakra-body-bg-darkest": { _light: "gray.40", _dark: "gray.800" },
        "chakra-body-bg-popper": { _light: "white", _dark: "gray.900" },
        "chakra-border-color": { _light: "gray.50", _dark: "blackAlpha.400" },
        "chakra-border-color-darker": { _light: "gray.500", _dark: "blackAlpha.400" },
        "chakra-placeholder-color": { _light: "gray.300", _dark: "whiteAlpha.400" },
        "chakra-border-color-lighter": { _light: "gray.100", _dark: "blackAlpha.500" },
        "chakra-border-color-lightest": { _light: "gray.50", _dark: "blackAlpha.400" },
        "chakra-primary": { _light: "brand.500", _dark: "brand.200" },
        "chakra-secondary": { _light: "niagara.500", _dark: "niagara.400" },
        "chakra-trinary": { _light: "violet.500", _dark: "violate.300" },
      },
    },
    styles: {
      global: (props: GlobalStyleProps): SystemStyleObject => ({
        body: {
          fontFamily: "body",
          fontSize: "fs.100",
          fontWeight: "medium",
          letterSpacing: "ls.100",
          color: "chakra-body-text",
          bg: "chakra-body-bg",
          lineHeight: "base",
          fontFeatureSettings: '"ss02" on, "ss03" on, "ss04" on',
        },
      }),
    },
    components: {
      Avatar,
      Badge,
      Button,
      List,
      Drawer,
      Link,
      Heading,
      Text,
      Alert,
      Input,
      NumberInput,
      Code,
      Form,
      FormLabel,
      Tooltip,
      Divider,
      Modal,
      Menu,
    },
    layerStyles,
    textStyles,
  },
  withDefaultColorScheme({ colorScheme: "brand" }),
  withDefaultColorScheme({ colorScheme: "niagara", components: ["Link"] }),
  withDefaultColorScheme({ colorScheme: "clear", components: ["Code"] })
);

// These are all the colors from default theme that get merged into our theme.
// Here we're stripping out the ones we don't want so we don't use them
//  and so that Stories have less noise.
[
  // "transparent",
  // "current",
  // "black",
  // "white",
  // "whiteAlpha",
  // "blackAlpha",
  // "gray",
  // "red",
  "orange",
  "yellow",
  "green",
  "teal",
  "blue",
  "cyan",
  "purple",
  "pink",
  "linkedin",
  "facebook",
  "messenger",
  "whatsapp",
  "twitter",
  "telegram",
].map(color => {
  delete theme.colors[color];
});

export default theme;

export const storyTheme = { colors: theme.colors, components: theme.components };
