import type { ComponentStyleConfig } from "@chakra-ui/theme";
import { mode } from "@chakra-ui/theme-tools";

const button: ComponentStyleConfig = {
  baseStyle: {
    letterSpacing: "ls.200",
    fontWeight: "bold",
    borderRadius: "lg",
    _hover: {},
    _active: {
      transform: "scale(.95)",
      _disabled: {
        transform: "none",
      },
    },
  },
  sizes: {
    md: {
      fontSize: "fs.100",
      px: 7,
      py: 6,
      "& > .chakra-button__icon, .chakra-icon": {
        fontSize: "fs.500",
      },
    },
    action: {
      px: 4,
      py: 3,
    },
    tiny: {
      h: "18px",
      w: "18px",
      minW: 0,
    },
  },
  variants: {
    solid: props => {
      const hoverBg = mode(`${props.colorScheme}.700`, `${props.colorScheme}.400`)(props);
      const disabledBg = mode("chakra-body-bg-darkest", `blackAlpha.200`)(props);
      return {
        _disabled: {
          bg: disabledBg,
          color: "chakra-body-text-light",
          opacity: 1,
        },
        _hover: {
          bg: hoverBg,
          _disabled: {
            bg: disabledBg,
          },
        },
        '&[data-testid="hover"]': {
          bg: hoverBg,
        },
      };
    },
    outline: {
      backgroundColor: "chakra-body-bg",
      borderColor: "chakra-border-color",
    },
    "outline-shadow": {
      backgroundColor: "chakra-body-bg",
      border: "1px solid",
      borderColor: "chakra-border-color",
      color: "chakra-body-text",
      filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08))",
      _hover: {
        filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2))",
      },
    },
    "gray-ghost": {
      color: "chakra-body-text",
      _hover: {
        color: "chakra-primary",
        backgroundColor: "chakra-body-bg-darker",
      },
      _active: {
        bg: "chakra-body-bg-darkest",
      },
    },
    refresh: props => ({
      color: "chakra-secondary",
      bg: "chakra-body-bg-darker",
      _hover: {
        bg: "chakra-border-color",
      },
      _active: {
        bg: "chakra-border-color",
      },
    }),
  },
  defaultProps: {},
};

export default button;
