import type { ComponentStyleConfig } from "@chakra-ui/theme";

const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontSize: "fs.75",
    color: "chakra-body-text",
    fontWeight: "medium",
    mb: 1,
  },
};

export default FormLabel;
