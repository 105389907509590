import type { ComponentStyleConfig } from "@chakra-ui/theme";
import { mode } from "@chakra-ui/theme-tools";

const Alert: ComponentStyleConfig = {
  parts: [],
  baseStyle: {
    container: {
      borderRadius: "8px",
      fontSize: "fs.400",
      letterSpacing: "ls.200",
      lineHeight: "20px",
      fontWeight: "medium",
      py: 4,
      px: 6,
      '& [aria-label="Close"]': {
        ml: 2,
      },
    },
    icon: {
      mr: 6,
    },
  },
  variants: {
    subtle: props => {
      const status = props.status as string;

      switch (status) {
        case "info":
          return {
            container: {
              color: mode("water.500", "water.300")(props),
              bg: mode("water.100", "water.800")(props),
              "& a": {
                color: mode("water.800", "water.100")(props),
                fontWeight: "bold",
                textDecoration: "underline",
              },
            },
            icon: {
              color: mode("water.500", "water.300")(props),
            },
          };
        default:
          return {
            container: {
              bg: "chakra-body-bg",
            },
          };
      }
    },
    toast: props => {
      return {
        title: {
          color: "chakra-body-text-darker",
          mr: 9,
          pb: 1,
        },
        description: {
          lineHeight: "20px",
          letterSpacing: "ls.200",
          mr: 9,
          "& .chakra-link": {
            fontWeight: "bold",
            color: "chakra-primary",
            fontSize: "fs.100",
          },
        },
        container: {
          bg: "chakra-body-bg",
          _dark: { bg: "gray.900" },
          borderRadius: "md",
          boxShadow: "2xl",
          fontSize: "fs.75",
          p: 4,
          maxW: "420px",
          '& [aria-label="Close"]': {
            m: 3,
          },
        },
        icon: {
          width: "32px",
          mr: 3,
        },
      };
    },
  },
};

export default Alert;
