import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const brandState = defineStyle({
  textTransform: "capitalize",
  py: "6px",
  px: "12px",
  background: "gray.30",
  color: "gray.600",
  borderRadius: "6px",
});

export const badge = defineStyleConfig({
  variants: { brandState },
  defaultProps: { variant: "brandState" },
});

export default badge;
