import { RetryLink } from "@apollo/client/link/retry";

const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    console.log(`[Network error]: ${error}`);
    return !!error && count < 3;
  },
});

export default retryLink;
