import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Modal: ComponentStyleConfig = {
  baseStyle: {
    body: {
      textAlign: "center",
      "> *": {
        marginBottom: 4,
      },
    },
    dialogContainer: {
      boxShadow: "3xl",
    },
    header: {
      color: "chakra-body-text-darkest",
    },
  },
};
export default Modal;
