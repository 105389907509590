/* eslint-disable @typescript-eslint/no-explicit-any */
import { GraphQLError } from "graphql";

import { TemplateLineError } from "@/generated/graphql";
import { ArgumentError } from "@/generated/graphql";

export function isArgumentError(obj: any): obj is ArgumentError {
  return obj.__typename === "ArgumentError";
}

export function isTemplateLineError(obj: any): obj is TemplateLineError {
  return obj.__typename === "TemplateLineError";
}

export function isGraphQLError(obj: any): obj is GraphQLError {
  return obj instanceof GraphQLError;
}

export function isUserError(obj: any): obj is any[] {
  return Array.isArray(obj) && obj.every(el => typeof el.__typename === "string");
}

export function isObject(obj: any): obj is object {
  return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
}

export function assertIsNode(e: EventTarget | null): asserts e is Node {
  if (!e || !("nodeType" in e)) {
    throw new Error(`Node expected`);
  }
}
