import { cssVar, SystemStyleFunction } from "@chakra-ui/theme-tools";

const $bg = cssVar("tooltip-bg");
const $arrowBg = cssVar("popper-arrow-bg");

const Tooltip = {
  baseStyle: (props: SystemStyleFunction) => {
    const bg = "chakra-body-bg";

    return {
      [$bg.variable]: `colors.${bg}`,
      px: 4,
      py: 3,
      bg: [$bg.reference],
      [$arrowBg.variable]: [$bg.reference],
      color: "chakra-body-text-darker",
      borderRadius: "md",
      fontWeight: "medium",
      letterSpacing: "ls.200",
      lineHeight: "20px",
      fontSize: "fs.75",
      boxShadow: "xl",
      maxW: "290px",
      zIndex: "tooltip",
    };
  },
};

export default Tooltip;
