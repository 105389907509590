const spacing = {
  space: {
    px: "1px",
    0.5: "0.125rem", // 2px
    1: "0.25rem", // 4px
    1.5: "0.375rem", // 6px
    2: "0.5rem", // 8px
    2.5: "0.625rem", // 10px
    3: "0.75rem", // 12px
    3.5: "0.875rem",
    4: "1rem", // 16px
    5: "1.25rem", // 20px
    6: "1.5rem", // 24px
    7: "1.75rem", // 28px
    8: "2rem", // 32px
    9: "2.25rem", // 36px
    10: "2.5rem", // 40px
    12: "3rem", // 48px
    14: "3.5rem", // 56px
    16: "4rem", // 64px
    20: "5rem", // 80px
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem", // 160px
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem", // 240px
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem", // 384px
  },
};

export default spacing;
