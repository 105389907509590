/**
 * BaseLayout wraps the entire app.
 */
import { PropsWithChildren, useMemo } from "react";

import useToggleColorModeKeyboard from "@/hooks/useToggleColorModeKeyboard";
import { ActionCableConnectionProvider } from "@/providers/useActionCableConnection";
import { LoggerProvider } from "@/providers/useLogger";

function BaseLayout({ children }: PropsWithChildren<Record<never, never>>) {
  useToggleColorModeKeyboard();

  const actionCableUrl = useMemo(() => {
    return process.env.NEXT_PUBLIC_BACKEND_BASE_URL?.replace("http", "ws") + "/cable";
  }, []);

  return (
    <ActionCableConnectionProvider url={actionCableUrl}>
      <LoggerProvider>{children}</LoggerProvider>
    </ActionCableConnectionProvider>
  );
}

export default BaseLayout;
