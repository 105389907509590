export const fonts = {
  body: "Satoshi, Arial, Helvetica, sans-serif",
  heading: "Satoshi, Arial, Helvetica, sans-serif",
  mono: "Menlo, monospace",
};

export const fontSizes = {
  "fs.25": "11px",
  "fs.50": "12px",
  "fs.75": "13px",
  "fs.100": "14px",
  "fs.200": "15px",
  "fs.300": "18px",
  "fs.500": "24px",
  "fs.600": "28px",
  "fs.700": "32px",
  "fs.800": "36px",
};

export const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

export const lineHeights = {
  normal: "normal",
  none: 1,
  shorter: 1.25,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: "2",
  "3": ".75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
};

export const letterSpacings = {
  "ls.50": "-0.01em",
  "ls.100": "0.01em",
  "ls.200": "0.02em",
  "ls.250": "0.025em",
  "ls.300": "0.04em",
};
