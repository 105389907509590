import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(numberInputAnatomy.keys);
import input from "./input";
const baseStyle = definePartsStyle({
  field: {
    pr: 12,
  },
  stepper: {
    _active: {
      background: "none",
    },
    border: "none",
    px: 2,
    fontSize: "lg !important",
    color: "brand.500",
  },
  stepperGroup: {
    py: 1,
    w: 12,
  },
});

export const numberInput = defineMultiStyleConfig({ ...input, baseStyle });

export default numberInput;
