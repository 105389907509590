import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Form: ComponentStyleConfig = {
  baseStyle: {
    helperText: {
      color: "chakra-body-text-light",
      fontSize: "fs.50",
      fontWeight: "normal",
      lineHeight: "18px",
      mt: 3,
    },
  },
};

export default Form;
