import { useContext, createContext, PropsWithChildren, Consumer } from "react";

export default function createProvider<T, K>(
  initialData: T,
  useHook: (arg0: K) => T
): [() => T, React.FC<PropsWithChildren<K>>, Consumer<T>] {
  const Context = createContext<T>(initialData);

  const useProvider = () => useContext(Context);

  function Provider(props: PropsWithChildren<K>) {
    const { children } = props;
    const createAppValues = useHook(props);
    return <Context.Provider value={createAppValues}>{children}</Context.Provider>;
  }

  return [useProvider, Provider, Context.Consumer];
}
