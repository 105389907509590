import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const outline = definePartsStyle({
  addon: {
    bg: "chakra-body-bg",
    color: "brand.500",
    borderColor: "chakra-border-color-lighter",
  },
  field: {
    color: "chakra-body-text-darker",
    borderColor: "chakra-border-color-lighter",
    _dark: {
      bg: "gray.900",
      _disabled: {
        opacity: 0.4,
      },
      _hover: {
        borderColor: "black",
      },
      _focus: {
        borderColor: "chakra-primary",
      },
    },
    _focus: {
      borderColor: "chakra-primary",
    },
    _placeholder: {
      color: "chakra-body-text-light",
    },
    _disabled: {
      bg: "gray.30",
      color: "chakra-body-text-lighter",
      borderColor: "chakra-border-color-lighter",
      opacity: 1,
    },
  },
});

const md = defineStyle({
  height: 12,
  fontSize: "fs.100",
});

const sizes = {
  md: definePartsStyle({ field: md, addon: md }),
};

export const input = defineMultiStyleConfig({
  defaultProps: {
    size: "md",
    variant: "outline",
  },
  sizes,
  variants: { outline },
});

export default input;
