import type { ComponentStyleConfig } from "@chakra-ui/theme";

const code: ComponentStyleConfig = {
  variants: {
    subtle: props => {
      if (props.colorScheme === "clear") {
        console.log("clear");
        return {
          background: "none",
        };
      }
      return {};
    },
    emphasis: {
      bg: "chakra-body-bg",
      border: "1px",
      borderColor: "chakra-border-color-lightest",
      borderRadius: "md",
      color: "chakra-primary",
      display: "inline-block",
      fontFamily: "Roboto Mono",
      fontSize: "fs.100",
      letterSpacing: "ls.50",
      fontWeight: "medium",
      maxW: "100%",
      mb: "-1px",
      minWidth: "0",
      overflow: "hidden",
      p: 2,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
};

export default code;
