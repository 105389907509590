import type { ComponentStyleConfig } from "@chakra-ui/theme";

const drawer: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      overflow: "auto",
    },
  },
};

export default drawer;
