import { tags as t } from "@lezer/highlight";
import { createTheme, CreateThemeOptions } from "@uiw/codemirror-themes";

import colors from "./foundations/colors";

const base = {
  overflow: "hidden",
  border: "1px solid #00000010",
  borderRadius: "10px",
  ".cm-lintRange-error": {
    backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="3">%3Cpath%20d%3D%22m0%202.5%20l2%20-1.5%20l1%200%20l2%201.5%20l1%200%22%20stroke%3D%22%23${colors.brick[500]}%22%20fill%3D%22none%22%20stroke-width%3D%22.7%22%2F%3E</svg>')`,
  },
};

const light: CreateThemeOptions = {
  theme: "light",
  ...base,
  settings: {
    background: colors.gray[30],
    foreground: colors.gray[600],
    caret: colors.gray[600],
    selection: colors.gray[40],
    selectionMatch: "#00BB9333",
    lineHighlight: colors.gray[40],
    gutterBackground: colors.gray[30],
    gutterForeground: colors.gray[300],
    gutterBorder: "#ffffff00",
  },
  styles: [
    { tag: [t.comment, t.quote], color: colors.gray[500] },
    { tag: [t.keyword], color: colors.brand[500], fontWeight: "bold" },
    { tag: [t.string, t.meta], color: colors.niagara[500], fontWeight: 700 },
    { tag: [t.typeName], color: "#DABAFF" },
    { tag: [t.definition(t.variableName)], color: "#6BDFFF" },
    { tag: [t.name], color: "#6BAA9F" },
    { tag: [t.variableName], color: "#ACF2E4" },
    { tag: [t.regexp, t.link], color: colors.niagara[500] },
  ],
};

const dark: CreateThemeOptions = {
  theme: "dark",
  ...base,
  settings: {
    background: colors.gray[900],
    foreground: "RGBA(255, 255, 255, 0.80)",
    caret: "RGBA(255, 255, 255, 0.80)",
    selection: colors.gray[800],
    selectionMatch: "#2EFFD233",
    lineHighlight: colors.gray[800],
    gutterBackground: colors.gray[900],
    gutterForeground: "RGBA(255, 255, 255, 0.50)",
    gutterBorder: "#ffffff00",
  },
  styles: [
    { tag: [t.comment, t.quote], color: "RGBA(255, 255, 255, 0.7)" },
    { tag: [t.keyword], color: colors.brand[200], fontWeight: "bold" },
    { tag: [t.string, t.meta], color: colors.niagara[400], fontWeight: 700 },
    { tag: [t.typeName], color: "#DABAFF" },
    { tag: [t.definition(t.variableName)], color: "#6BDFFF" },
    { tag: [t.name], color: "#6BAA9F" },
    { tag: [t.variableName], color: "#ACF2E4" },
    { tag: [t.regexp, t.link], color: colors.niagara[500] },
  ],
};

export const codemirror = {
  light: createTheme(light),
  dark: createTheme(dark),
};

export default codemirror;
