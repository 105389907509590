
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AppsActivateError": [
      "ArgumentError"
    ],
    "AppsCreateError": [
      "ArgumentError"
    ],
    "AppsCreateServicesError": [
      "ArgumentError"
    ],
    "AppsDeleteError": [
      "ArgumentError"
    ],
    "AppsUpdateError": [
      "ArgumentError",
      "TemplateLineError"
    ],
    "BuildArgsCreateError": [
      "ArgumentError"
    ],
    "BuildArgsDeleteError": [
      "ArgumentError"
    ],
    "BuildArgsUpdateError": [
      "ArgumentError"
    ],
    "ClaimerUnion": [
      "Environment",
      "User"
    ],
    "CloudIntegrationsCreateError": [
      "ArgumentError"
    ],
    "CloudIntegrationsDeleteError": [
      "ArgumentError"
    ],
    "CloudIntegrationsUpdateError": [
      "ArgumentError"
    ],
    "ClustersDeleteError": [
      "ArgumentError"
    ],
    "ClustersImportError": [
      "ArgumentError"
    ],
    "DatabaseID": [
      "App",
      "AuroraDatabase",
      "AuroraPool",
      "BuildArg",
      "CloudIntegration",
      "CloudProvider",
      "CloudSqlPool",
      "Cluster",
      "Customer",
      "CustomerSettings",
      "Deploy",
      "EmailAddress",
      "Environment",
      "Identity",
      "IdentityProvider",
      "Invite",
      "Log",
      "PoolItem",
      "RdsDatabase",
      "RdsPool",
      "ReleaseInstallation",
      "Repository",
      "RepositoryAnalysis",
      "Service",
      "User",
      "Workspace"
    ],
    "DatabaseInterface": [
      "AuroraDatabase",
      "RdsDatabase"
    ],
    "DatabasePoolConfigurationInterface": [
      "AuroraPoolConfiguration",
      "CloudSqlPoolConfiguration",
      "RdsPoolConfiguration"
    ],
    "DatabasePoolInterface": [
      "AuroraPool",
      "CloudSqlPool",
      "RdsPool"
    ],
    "DatabasePoolSettingsProviderUnion": [
      "Cluster",
      "StandaloneDatasetConfiguration"
    ],
    "DatabaseSettingsProviderInterface": [
      "Cluster",
      "StandaloneDatasetConfiguration"
    ],
    "EchoError": [
      "OperationError"
    ],
    "EnvironmentsCreateError": [
      "ArgumentError"
    ],
    "IdentityProvidersCreateError": [
      "ArgumentError"
    ],
    "InvitesAcceptError": [
      "ArgumentError"
    ],
    "LogsInterface": [
      "Cluster",
      "PoolItem"
    ],
    "Node": [
      "App",
      "AuroraDatabase",
      "AuroraPool",
      "BuildArg",
      "CloudIntegration",
      "CloudProvider",
      "CloudSqlPool",
      "Cluster",
      "Customer",
      "CustomerSettings",
      "Deploy",
      "EmailAddress",
      "Environment",
      "Identity",
      "IdentityProvider",
      "Invite",
      "Log",
      "PoolItem",
      "RdsDatabase",
      "RdsPool",
      "ReleaseInstallation",
      "Repository",
      "RepositoryAnalysis",
      "Service",
      "User",
      "Workspace"
    ],
    "OrganizationsFindOrCreateError": [
      "ArgumentError"
    ],
    "PaymentsGenerateUrlError": [
      "ArgumentError"
    ],
    "PaymentsValidateError": [
      "ArgumentError"
    ],
    "PipelinesDeployError": [
      "ArgumentError"
    ],
    "PoolInterface": [
      "AuroraPool",
      "CloudSqlPool",
      "RdsPool"
    ],
    "PoolableUnion": [
      "AuroraDatabase",
      "RdsDatabase"
    ],
    "PoolsCheckinError": [
      "ArgumentError"
    ],
    "PoolsCheckoutError": [
      "ArgumentError"
    ],
    "PoolsCreateStandaloneError": [
      "ArgumentError"
    ],
    "PoolsDeleteError": [
      "OperationError"
    ],
    "PoolsUpdateDatabaseError": [
      "ArgumentError"
    ],
    "ReleaseInstallationsRegisterError": [
      "ArgumentError"
    ],
    "ServicesUpdateError": [
      "ArgumentError"
    ],
    "UserError": [
      "ArgumentError",
      "OperationError",
      "TemplateLineError"
    ],
    "UsersLogoutError": [
      "OperationError"
    ],
    "UsersUpsertProfileError": [
      "ArgumentError"
    ],
    "WorkspacesApplyPromoCodeError": [
      "ArgumentError"
    ],
    "WorkspacesCreateError": [
      "ArgumentError"
    ]
  }
};
      export default result;
    