/* eslint-disable react/jsx-filename-extension */
import { useEffect } from "react";

import { useColorMode } from "@chakra-ui/react";

// 🤫 Shhh... Easter egg
export default function useToggleColorModeKeyboard() {
  const { toggleColorMode } = useColorMode();

  // Add event listeners
  useEffect(() => {
    const downHandler = ({ key }: KeyboardEvent) => {
      if (key === "F10") {
        toggleColorMode();
      }
    };

    window.addEventListener("keydown", downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [toggleColorMode]); // Empty array ensures that effect is only run on mount and unmount

  return;
}
