/* eslint-disable react/jsx-filename-extension */
import { useMemo } from "react";

import createProvider from "@/providerFactories/createProvider";
class Logger {
  error(msg: string) {
    console.error(msg);
  }
  info(msg: string) {
    console.log(msg);
  }
}

function _useLogger() {
  const logger = useMemo(() => new Logger(), []);

  return logger;
}

export const [useLogger, LoggerProvider] = createProvider<Logger, Record<never, never>>(
  new Logger(),
  _useLogger
);
